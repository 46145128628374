import type { NextPage } from 'next'

import styles from '../styles/pages/Custom404.module.scss'

import CustomMetaData from '../components/general/customMetaData/CustomMetaData'
import { GenericPageLayoutProps } from '../components/general/genericPageLayout/GenericPageLayout'

import { useGlobalContentContext } from '../context/GlobalContentContext'
import NewsPreviewItem, { NewsProps } from '../components/news/News'

import { parseGenericPage } from '../utils/parsers'
import { getAPI, createMarkup } from '../utils/helpers'


type Custom404Props = {} & GenericPageLayoutProps

const Custom404 = (props: Custom404Props) => {
  const { setData } = useGlobalContentContext()


  return (
    <div className={styles.container}>
      <CustomMetaData
        title="Page not found"
      />

      <div className={styles.main}>
        <section className={styles.hero}>
          <h1>Page not found</h1>
        </section>
      </div>
    </div>
  )
}

export async function getStaticProps() {
  const page: GenericPageLayoutProps = await parseGenericPage(
    `${getAPI()}/homepage/`
  )

  return {
    props: {
      originalResponse: page.originalResponse,
      globalData: page.globalData,
      title: page.title,
      error: '404'
    },
    revalidate: NEXTJS_REVALIDATE_PAGE_IN_SECONDS,
  }
}

export const NEXTJS_REVALIDATE_PAGE_IN_SECONDS = 10
export default Custom404
